import Zoom from '@/logic/Zoom';
import type Audio from './Audio';
import type Staff from './Staff';
import type { Pixel } from './Unit';
import type Unit from './Unit';

export enum SourceType {
  Score = 'score',
  AudioImage = 'wave',
  Image3D = '3d',
  Grid = 'grid'
}

export interface Image3DRowData {
  staves: Staff[];
  y: Pixel;
  height: Pixel;
  end: Pixel;
  start: Pixel;
}

export interface Image3DPageData {
  image?: string;
  height: number;
  rows: Array<Image3DRowData>;
}

interface SourceData {
  pixelUnit: Unit<Pixel>;
  width: Pixel;
  height: Pixel;
  staves: Staff[];
  imageUrl: string;
  type: SourceType;
  track?: Audio;
  license?: string;
  name: string | null;
  info: string;
  pages?: Array<Image3DPageData>;
  contributors?: Record<string, string>;
  refs?: Record<string, string>;
  source?: string;
  year?: string;
}

export default class Source implements Readonly<SourceData> {
  id = 0;

  readonly pixelUnit: Unit<Pixel>;

  readonly width: Pixel;

  readonly height: Pixel;

  readonly staves: Staff[];

  imageUrl: string;

  readonly type: SourceType;

  readonly track?: Audio;

  readonly license?: string;

  readonly name: string | null;

  readonly info: string;

  readonly shouldSnap: boolean;

  readonly pages?: Array<Image3DPageData>;

  readonly contributors?: Record<string, string> | undefined;

  readonly refs?: Record<string, string>;

  readonly source?: string | undefined;

  readonly year?: string | undefined;

  zoom: Zoom;

  protected constructor(parsed: SourceData) {
    this.pixelUnit = parsed.pixelUnit;
    this.width = parsed.width;
    this.height = parsed.height;
    this.staves = parsed.staves;
    this.imageUrl = parsed.imageUrl;
    this.type = parsed.type;
    this.track = parsed.track;
    this.name = parsed.name;
    this.info = parsed.info;
    this.pages = parsed.pages;
    this.shouldSnap = parsed.type !== 'wave';
    this.contributors = parsed.contributors;
    this.refs = parsed.refs;
    this.source = parsed.source;
    this.year = parsed.year;
    this.zoom = new Zoom();
    this.license = parsed.license;
  }

  setId(id: number): void {
    this.id = id;
  }

  setPage(index: number): void {
    if (!this.pages) return;
    const splittedImageUrl = this.imageUrl.split('/');
    splittedImageUrl.pop();
    this.imageUrl = `${splittedImageUrl.join('/')}/${this.pages[index].image}`;
  }

  setZoom(zoom: Zoom): void {
    this.zoom.exponent = zoom.getExponentForValue(zoom.value);
  }

  scaled(scale: number): Source {
    const data = { ...this };
    return new Source({
      ...data,
      width: data.width * scale,
      pixelUnit: data.pixelUnit.getScaled(scale),
    });
  }
}
