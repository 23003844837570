import type { Unit, Second } from '@/data';
import { Audio } from '@/data';
import type { AudioImageFileFormat } from './SourceParser';

/**
  * An audio file object
  * Contains the relative path to an audio file
  * Contains a time conversion object
  * May contain wave image file objects
  */
export type AudioFileFormat = {
  /** The audio file url */
  file: string;
  /** The synchronization format, to be parsed in a unit  */
  'onset-date': string;
  /** Additional info about the source */
  info?: string;
  contributors?: Record <string, string>;
  refs?: Record <string, string>;
  year?: string;
  /** Images associated with this track */
  images?: AudioImageFileFormat[];
  /** The license associated with this track, if any */
  license?: string;
} | {
  /** The youtube id for youtube integration */
  'yt-id': string;
  /** The fallback audio file, if any */
  file?: string;
  /** The synchronization format, to be parsed in a unit  */
  'onset-date': string;
  /** Additional info about the source */
  info?: string;
  contributors?: Record <string, string>;
  refs?: Record <string, string>;
  year?: string;
  /** Images associated with this track */
  images?: AudioImageFileFormat[];
  /** The license associated with this track, if any */
  license?: string;
};

/**
 * An image file object
 *
 * Contains:
 * * the relative path to an image file
 * * a time conversion object
 *
 * For score images, the conversion should be onset -> pixel
 *
 * For wave images, the conversion should be second -> pixel
 */

export default class AudioParser extends Audio {
  static fromFormat(
    secondUnit: Unit<Second>,
    url?: string,
    youtubeId?: string,
    info = '',
    contributors:Record<string, string> = {},
    refs:Record<string, string> = {},
    year = '',
  ): Audio {
    if (!url && !youtubeId) throw new Error('No source file or id was provided');
    return new Audio({
      secondUnit,
      url,
      youtubeId,
      info,
      contributors,
      refs,
      year,
    });
  }
}
