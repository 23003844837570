import type { Second } from './Unit';
import type Unit from './Unit';

type AudioData = {
  secondUnit: Unit<Second>;
  /** The url to the audio file, if any */
  url?: string;
  /** The youtube id of the associated video, if any */
  youtubeId?: string;
  /** additional info */
  info: string;
  contributors: Record<string, string>;
  refs: Record<string, string>;
  year: string;
};

export default class Audio implements Readonly<AudioData> {
  readonly secondUnit: Unit<Second>;

  readonly url?: string;

  readonly youtubeId?: string;

  readonly info: string;

  readonly contributors: Record<string, string>;

  readonly refs: Record<string, string>;

  readonly year: string;

  protected constructor(parsed: AudioData) {
    this.secondUnit = parsed.secondUnit;
    this.url = parsed.url;
    this.youtubeId = parsed.youtubeId;
    this.info = parsed.info;
    this.contributors = parsed.contributors;
    this.refs = parsed.refs;
    this.year = parsed.year;
  }

  getInfoShort(): string {
    // Return one line, raw text

    const details = [];

    if (this.contributors.artist) {
      details.push(`${this.contributors.artist}`);
    }
    if (this.contributors.performer) {
      details.push(`${this.contributors.performer}`);
    }
    let line = details.join(', ');

    if (this.year) {
      line += ` (${this.year})`;
    }

    if (line) return line;

    // ================================
    if (this.info.length) {
      return this.info;
    }

    // ================================
    let line2 = '';

    if (this.contributors.editor) {
      line2 += this.contributors.editor;
    }

    return line2;
  }

  getInfo(): string {
    const lines = [];

    // ==================================
    const details = [];

    if (this.contributors.artist) {
      details.push(`<b>${this.contributors.artist}</b>`);
    }
    if (this.contributors.performer) {
      details.push(`<b>${this.contributors.performer}</b>`);
    }
    let line = details.join(', ');

    if (this.year) {
      line += ` (${this.year})`;
    }

    if (line) lines.push(line);

    // ================================
    let line2 = '';

    if (this.contributors.editor) {
      line2 += this.contributors.editor;
    }

    if (line2) lines.push(line2);

    // ===============================
    if (this.info.length) {
      lines.push(this.info);
    }

    // ===============================
    return lines.join('<br>');
  }
}
